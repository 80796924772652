body {
  margin: 0;
  font-family:
    "Inter",
    -apple-system,
    BlinkMacSystemFont,
    "Segoe UI",
    "Roboto",
    "Oxygen",
    "Ubuntu",
    "Cantarell",
    "Fira Sans",
    "Droid Sans",
    "Helvetica Neue",
    sans-serif;
  font-variant-ligatures: no-contextual;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-y: unset;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

/**
Dirty, dirsty hack to force Grommet Tab panels to have a 100% height.
Unfortunately they don't accept custom styles as props. (like most components do)

In some cases we want tab content to contain absolutely positioned elements, in which
case we need the container elements to have a specified height.

TODO: Find a better solution
**/

div[class^="StyledTabs__StyledTabPanel"] {
  height: 100%;
}

.hide-scroll {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.hide-scroll::-webkit-scrollbar {
  display: none;
}

.App {
  min-height: calc(var(--vh, 1vh) * 100);
}

body,
html {
  overscroll-behavior: none;
}

:root {
  --svh: 1vh;
}

@supports (max-height: 100svh) {
  :root {
    --svh: 1svh;
  }
}

#liveview-portal {
  position: relative;
  z-index: 10;
}
